<template>
  <div>
    <custom-modal :visible="isModalVisible" :zIndex="1100" :closable="false" :footer="null" :transition-name="''"
      class="upgrade-modal-container">
      <!-- isModalVisible -->
      <template #modal-title>
        <div class="modal-header flex items-center justify-between">
          <div class="flex items-center modal-title">
            {{ operType === "DEGRADE_SUBSCRIPTION" ? 'Update to' : 'Upgrade to' }}
            {{ currentSelectedName }}
          </div>
          <div class="close-button" @click="handleCloseModal"></div>
        </div>
      </template>

      <template #modal-content>
        <div class="modal-body">

          <div class="promotion-plan"
            :data-error-msg="discount.code === promotionValue ? discount.couponCheckFailMsg : ''">
            <div class="flex w-full justify-between items-center">
              <p :class="`flex price-container ${discount.checkSuccessBoolean ? 'items-center' : 'items-baseline'}`">
                <template v-if="!discount.checkSuccessBoolean">
                  <span>
                    {{ discount.totalPrice }}
                  </span>
                  <span style="color:#646A73;margin-left: 7px;">
                    {{ '/ ' }}
                    {{ operType === 'UPGRADE_SUBSCRIPTION' ?
                      'Amount due today' :
                      cycleTypes[cycleType].name }}
                  </span>
                </template>
                <template v-else>
                  <span style="color: #FF2154">
                    {{ discount.discountedPrice }}
                  </span>
                  <span class="discount-info">
                    <span style="text-decoration-color: white; text-decoration-line: line-through; margin-right: 8px;">
                      {{ ` ${discount.originalPrice} ` }}
                    </span>
                    Get {{ discount.couponDiscountType === 'percentage' ? `${discount.couponPercent}%` :
                      `${discount.couponPrice}` }} off
                  </span>
                </template>
              </p>
              <div class="promotion-code-entry">
                <div
                  :class="['promotion-code-entry-inputWrapper', !promotionVisible && 'promotion-code-entry-inputWrapper-blur']">

                  <input
                    :style="{ borderColor: (discount.code === promotionValue && discount.checkSuccessBoolean === false) ? '#F54A45' : '' }"
                    :class="[
                      'checkout-input',
                      promotionVisible ? 'checkout-input-focus' : 'checkout-input-blur',
                      promotionVisible && !promotionValue && `checkout-input-noValue`
                    ]" v-model="promotionValue" type="text" placeholder="Add promo code" ref="inputRef"
                    @input="handlePromotionInput" @focus="handlePromotionFocus" @blur="handlePromotionBlur">

                  <button @click="handleSubmit"
                    :class="[promotionValue && !submitLoading && discount.code !== promotionValue ? 'promotion-code-entry-applyButton-visible' : '', 'promotion-code-entry-applyButton']">
                    <div class="flex justify-center items-center" v-show="!submitLoading">
                      <span>
                        Submit
                      </span>
                    </div>
                  </button>

                  <div class="flex justify-center items-center submit-loading">
                    <img :src="submitLoadingIcon" alt="" v-show="submitLoading">
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="plan-details">
            <p v-if='operType === "DEGRADE_SUBSCRIPTION"' class="payment-info">
              <img :src="infoIcon" />
              The new plan starts at your next billing cycle.
            </p>
            <div class="start-detail">
              <p>Enjoy your {{ currentSelectedName }} features starting</p>
              <p>{{ dayjs(operType === 'UPGRADE_SUBSCRIPTION' ? discount.currentSubStartTimeMills :
                discount.nextSubStartTimeMills).format('MMMM DD, YYYY') }}</p>
            </div>
            <template v-if="operType === 'UPGRADE_SUBSCRIPTION'">
              <div class="present-prorate-detail">
                <p>{{ discount.presentProrateDescription }}</p>
                <p>{{ discount.presentProrate }}</p>
              </div>
              <div class="original-prorate-detail">
                <p>{{ discount.originalProrateDescription }}</p>
                <p>{{ discount.originalProrate }}</p>
              </div>
            </template>
            <div class="price-detail" style="font-weight: 500;">
              <div>Total</div>
              <div>{{ discount.totalPrice }}</div>
            </div>
          </div>
        </div>
      </template>
      <template #operate-button>
        <div class="flex justify-center button-container">
          <custom-btn :customStyle="customStyle" :showPrefixIcon="false" @click="(e) => handleUpgrade(e)"
            :disabled="isLoading" activeColor="linear-gradient(289deg, #6F46F4 5.38%, #957AEC 99.95%)">
            <div class="flex items-center justify-center">
              <div class="flex items-center relative">
                <LoadingOutlined :style="{ position: 'absolute', left: '-21px' }" v-show="isLoading" />
                <!-- id和text，ga report匹配需要，不要修改 -->
                <p id="upgrade-btn" :ga-data="currPackageCode">{{ operType === 'DEGRADE_SUBSCRIPTION' ? 'Update' :
                  'Upgrade now' }}</p>
              </div>

            </div>
          </custom-btn>
        </div>
      </template>
    </custom-modal>
    <successful-modal :isShowModal="isShowSuccessModal" @close="handleSuccessModalClose" :title="successModalTitle" />
  </div>
</template>

<script setup>
import close_icon from '@/assets/common-icons/icon_close.svg';
import CustomModal from '@/components/boolv-ui/custom-modal/custom-modal.vue';
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";
import dayjs from 'dayjs';
import { useWindowSize } from '@vueuse/core';
import { LoadingOutlined } from '@ant-design/icons-vue';
import { usePremiumStore } from '@/store/premium.js';
import { useUserStore } from '@/store/user.js';
import { storeToRefs } from 'pinia';
import { debounce } from 'lodash';
import { reactive, ref, watchEffect, inject, computed } from 'vue';
import submitLoadingIcon from '@/assets/premium/submit_loading.svg'
import infoIcon from '@/assets/premium/info_icon.svg'
import { eventTracking } from '@/utils/eventTracking.js';
import successfulModal from '../modal/successful-modal.vue'
import { cycleTypes } from '../constants.js';
import { modalManager } from '@/components/boolv-ui/global-modal/reactive';
import {
  userSubscribe,
  checkPromotionCode,
} from "@/api/premium/index";
import {
  MessageSuccess,
  MessageError,
  destroy,
} from "@/components/boolv-ui/Message/index.js";
import match from '@/utils/match.js';
import { authenticator } from '@/utils';

const premium = usePremiumStore();
const { isUpdateClicked, isAppsumoSubscribedClick, subscriptionList } = storeToRefs(premium);
const isShowSuccessModal = ref(false)
const successModalTitle = ref('')
const isModalVisible = ref(false);
const isLoading = ref(false);
const promotionVisible = ref(false);
const promotionValue = ref(null);
const currentSelectedName = ref('Pro');
const submitLoading = ref(false);
const discount = ref(null);
const operType = inject('operType');
const cycleType = inject('cycleType');
const [subscriptionState, updateSubscriptionState] = inject('subscriptionState');
const planDetails = inject('planDetails');
const currentSelectedOrder = inject('currentSelectedOrder');
const currPackageCode = inject('currPackageCode');

// element ref
const inputRef = ref(null);
const convertToValidChar = code => {
  if (match.digit(code) || match.uppercase(code)) {
    return String.fromCodePoint(code);
  } else if (match.lowercase(code)) {
    return String.fromCodePoint(code - 32);
  } else {
    return '';
  }
}

function toFixed (number, maxFractionDigits = 2) {
  let [integer, fraction] = number.toFixed(maxFractionDigits).split('.');
  fraction = fraction.replace(/0+$/, '') || '0';

  return `${integer}.${fraction}`;
}

function initDiscountValue (value = {}) {

  // TODO:更改价格方面
  const orderPrice = planDetails.originalPrice;
  const nextSubStartTimeMills = planDetails.nextSubStartTimeMills;
  const currentSubStartTimeMills = planDetails.currentSubStartTimeMills;
  const presentProrate = planDetails.presentProrate;
  const originalProrate = planDetails.originalProrate;
  const totalPrice = planDetails.totalPrice;
  const presentProrateDescription = planDetails.presentProrateDescription;
  const originalProrateDescription = planDetails.originalProrateDescription;
  const nextPeriodSubPriceFormat = planDetails.nextPeriodSubPriceFormat
  // console.log(currentSelectedOrder);
  //数据结构根据接口而动
  console.log(value);
  return {
    code: value.code || null,
    checkSuccessBoolean: 'checkSuccessBoolean' in value ? value.checkSuccessBoolean : null,
    couponCheckFailMsg: value.couponCheckFailMsg || '',
    nextSubStartTimeMills: value.nextSubStartTimeMills || nextSubStartTimeMills,
    currentSubStartTimeMills: value.currentSubStartTimeMills || currentSubStartTimeMills,
    originalPrice: value.originalPrice || orderPrice,
    presentProrate: value.presentProrate || presentProrate,
    presentProrateDescription: value.presentProrateDescription || presentProrateDescription,
    originalProrate: value.originalProrate || originalProrate,
    originalProrateDescription: value.originalProrateDescription || originalProrateDescription,
    discountedPrice: value.discountedPrice || orderPrice,
    nextPeriodSubPriceFormat: value.nextPeriodSubPriceFormat || nextPeriodSubPriceFormat,
    totalPrice: value.totalPrice || totalPrice,

    couponDiscountType: value.couponDiscountType || 'fixed_amount',
    couponPrice: (value.couponPrice || 0),
    couponPercent: (value.couponPercent / 100) || 100,
  };
}

watchEffect(() => {
  if (isUpdateClicked.value) {
    isModalVisible.value = true;
    discount.value = initDiscountValue();
    console.log(currentSelectedName.value);
  }
});

// watchEffect(() => {
//   // if (currentSelectedOrder.value in subscriptionList.value) {
//   //   discount.value = initDiscountValue();
//   // }
//   discount.value = initDiscountValue();
// });
watchEffect(() => {
  if (currentSelectedOrder.value) {
    subscriptionList.value.forEach((item) => {
      if (item.packageCode === currentSelectedOrder.value) {
        currentSelectedName.value = item.subTag;
      }
    })
    console.log(currentSelectedName.value);
  }
})
const handleSubmit = () => {
  submitLoading.value = true;
  inputRef.value.setAttribute('disabled', true);

  checkPromotionCode({
    couponCode: promotionValue.value,
    packageCode: currentSelectedOrder.value,
  }).then(({ data }) => {
    const parsedData = (value) => {
      const { subPriceBody, subPriceHeader } = value;
      let presentProrate = '', presentProrateDescription = '';
      let originalProrate = '', originalProrateDescription = '';
      if (subPriceBody.itemList && subPriceBody.itemList.length > 0) {
        subPriceBody.itemList.forEach(item => {
          if (item.amount > 0) {
            presentProrate = item.amountFormat;
            presentProrateDescription = item.description;
            console.log(item, '>0');
          }
          else {
            originalProrate = item.amountFormat;
            originalProrateDescription = item.description;
            console.log(item, '<0');
          }
        })
      }
      return {
        checkSuccessBoolean: value.checkSuccessBoolean,
        nextSubStartTimeMills: subPriceBody.nextSubStartTimeMills,
        currentSubStartTimeMills: subPriceBody.currentSubStartTimeMills,
        originalPrice: subPriceHeader.originalPriceFormat,
        presentProrate: presentProrate,
        presentProrateDescription: presentProrateDescription,
        originalProrate: originalProrate,
        originalProrateDescription: originalProrateDescription,
        discountedPrice: subPriceHeader.discountedPriceFormat,
        totalPrice: subPriceBody.totalFormat,
        couponDiscountType: subPriceHeader.couponDiscountType,
        couponPrice: subPriceHeader.discountPriceFormat,
        couponPercent: subPriceHeader.couponPercent,
        nextPeriodSubPriceFormat: value.nextPeriodSubPriceFormat,
      }
    }

    if (data.checkSuccessBoolean) {
      discount.value = initDiscountValue(parsedData(data));
      discount.value.code = promotionValue.value;
      initPrmotionState();
    }
    else {
      discount.value = initDiscountValue({
        code: promotionValue.value,
        checkSuccessBoolean: false,
        couponCheckFailMsg: data.couponCheckFailMsg,
      });
      console.log(discount.value.code, promotionValue.value);
    }
  }).catch(({ code, msg }) => {

  }).finally(() => {
    submitLoading.value = false;
    inputRef.value.removeAttribute('disabled');
  });
}

const initPrmotionState = () => {
  submitLoading.value = false;
  promotionVisible.value = false;
  promotionValue.value = '';
}
const handlePromotionFocus = () => {
  promotionVisible.value = true;
}

const handlePromotionInput = (e) => {
  const value = Array.prototype.reduce.call(promotionValue.value, (value, char) => {
    return value + convertToValidChar(char.charCodeAt(0));
  }, '');

  promotionValue.value = value;
}

const handleCloseModal = () => {
  isUpdateClicked.value = false;
  isModalVisible.value = false;
  discount.value = initDiscountValue();
  initPrmotionState();
}

const handlePromotionBlur = () => {
  if (!promotionValue.value) {
    initPrmotionState();
  }
}

const handleUpgrade = debounce(() => {

  isLoading.value = true;
  const payload = {
    packageCode: currentSelectedOrder.value,
    couponCode: discount.value.checkSuccessBoolean ? discount.value.code : undefined,
  };

  userSubscribe(payload).then(async ({ data, code }) => {
    const {
      paymentUrl,
      couponCheckFailMsg,
      couponCheckSuccessBoolean,
      subscriptionOperType,
    } = data;

    if (!couponCheckSuccessBoolean) {
      discount.value = initDiscountValue({
        code: discount.value.code,
        checkSuccessBoolean: couponCheckSuccessBoolean,
        couponCheckFailMsg,
      });
    } else {

      if (code === 0) {
        switch (subscriptionOperType) {
          case 'NEW_SUBSCRIPTION':
            window.location.href = paymentUrl;
            handleCloseModal();
            break;
          case 'UPGRADE_SUBSCRIPTION':
            await authenticator.refresh();
            await updateSubscriptionState();
            handleCloseModal();
            isShowSuccessModal.value = true
            successModalTitle.value = 'Upgrade successfully!'
            // MessageSuccess("Upgrade successfully");
            break;
          case 'DEGRADE_SUBSCRIPTION':
            await updateSubscriptionState();
            eventTracking("booltool_subscribe_confirm", { is_success: true, subscription_type: 'downgrade', plan_option: payload.packageCode });
            handleCloseModal();
            // MessageSuccess("Downgrade successfully");
            isShowSuccessModal.value = true
            successModalTitle.value = 'Downgrade successfully!'
            break;
          case 'RECOVER_SUBSCRIPTION':
            await updateSubscriptionState();
            handleCloseModal();
            // MessageSuccess("Successfully recover your subscription")
            isShowSuccessModal.value = true
            successModalTitle.value = 'Successfully recover your subscription!'
            break;
          default:
            MessageError("Error")
            break;
        }
      }
    }
  }).catch(data => {

    if (data.code === 101004) {
      // appsumo订阅了之后点击订阅按钮
      isAppsumoSubscribedClick.value = true;
    } else if (data.code === 101008) {
      modalManager.applyTemplate('switch_to_monthly');
      handleCloseModal();
    }

  }).finally(() => {
    setTimeout(destroy, 2000);
    isLoading.value = false;
    isUpdateClicked.value = false;

    if (discount.value.checkSuccessBoolean) {
      isModalVisible.value = false;
      initPrmotionState();
    }
  })
}, 500);

const customStyle = reactive({
  width: '160px',
  height: '36px',
  background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)',
  'border-radius': '60px',
});

const handleSuccessModalClose = () => {
  isShowSuccessModal.value = false
}
</script>

<style lang="less" scoped>
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.header-brief {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #646A73;
}

.modal-header {
  padding-bottom: 36px;
  border-bottom: 1px solid #E7E7E7;
}

.modal-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1F2329;
}

.close-button {
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #EDEDED;
  }

  &::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('~@/assets/common-icons/icon_close.svg') no-repeat center/20px 20px;
  }
}

.modal-body {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #060606;

}

:global(.upgrade-modal-container .ant-modal-content) {
  border-radius: 8px;
}


:global(.upgrade-modal-container .ant-modal-header) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.promotion-plan {
  position: relative;
  padding: 10px 18px 20px 18px;
  line-height: 30px;

  &::after {
    content: attr(data-error-msg);
    display: block;
    font-size: 14px;
    line-height: 1;
    color: #F54A45;
    text-align: right;
  }
}

.plan-details {
  background: #F5F6F7;
  padding: 16px 18px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #646A73;
  width: 100%;

  .start-detail {
    display: flex;
    justify-content: space-between;
  }

  .present-prorate-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .original-prorate-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .price-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.promotion-code-entry {
  display: inline-block;

}

.promotion-code-entry-inputWrapper {
  position: relative;
  text-align: flex-end;
}

.promotion-code-entry-applyButton {
  position: absolute;
  background: transparent;
  border: none;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 0;
  -webkit-transform: scale(.8);
  -ms-transform: scale(.8);
  transform: scale(.8);
  transition: opacity .3s ease, -webkit-transform .3s ease;
  transition: opacity .3s ease, transform .3s ease;
  transition: opacity .3s ease, transform .3s ease, -webkit-transform .3s ease;
  z-index: 100;
  color: #875EFF;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.promotion-code-entry-applyButton-visible {
  cursor: pointer;
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.submit-loading {
  position: absolute;

  bottom: 0;
  right: 12px;
  top: 0;

  img {
    width: 18px;
    animation: rotate 2s linear infinite;
  }

}

.promotion-code-entry-label {
  display: inline-block;
  color: #875EFF;
  cursor: pointer;
  // transition-property: padding;
  white-space: nowrap;
  line-height: 32px;
}

.checkout-input {
  display: inline-flex;
  width: 150px;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  font-size: 14px;
  background: #ffffff;
  overflow: visible;
  border: 1px solid #875EFF;
  cursor: pointer;
  transition: width .5s, background-color .5s, border-color .5s, padding .5s;

  &::-webkit-input-placeholder {
    position: absolute;
    height: 32px;
    line-height: 34px;
    vertical-align: top;
    right: 0;
    font-size: 14px;
    color: #875EFF;
    transition: right .5s, color .5s;
  }

  &:focus {
    outline: none;
    z-index: 2;
  }
}

.checkout-input-focus {
  width: 180px;
  padding-inline: 12px 70px;
  cursor: text;

  &::-webkit-input-placeholder {
    right: 52px;
    color: #aaa;
  }
}

.checkout-input-blur {
  padding-inline: 0;
  background-color: transparent;
  border-color: transparent;
}

.checkout-input-noValue {
  cursor: pointer;
  padding-inline: 12px;
}

.button-container {
  margin-top: 32px;
}

.price-container>span:first-of-type {
  font-size: 24px;
  line-height: 220%;
  font-weight: 500;
}

.price-container .discount-info {
  padding: 0 6px;
  line-height: 22px;
  color: white;
  font-size: 14px;
  margin-left: 8px;
  border-radius: 2px;
  background-color: #FF2154;
}

// .checkout-input-container,
// .input-container,
// .checkout-input {
//   transition-duration: .2s;
//   transition-timing-function: ease;
// }

.payment-info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 16px;
  color: #646A73;
}

.payment-info>img {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 7px;
}

@screen s {
  :global(.upgrade-modal-container) {
    height: 100%;
    min-width: 100%;
    margin: 0;
  }
  
  :global(.upgrade-modal-container > .ant-modal-content) {
    height: 100%;
    border-radius: 0 !important;
    overflow-y: auto;
  }

  .modal-header {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0;
    border-bottom: none;
  }

  .close-button {
    order: -1;
  }

  .close-button::before {
    width: 18px;
    height: 18px;
    background: url('~@/assets/arrow_icon.svg') no-repeat center/contain;
  }

  .close-button::after {
    content: 'Back';
    font-size: 16px;
    margin-left: 8px;
  }

  .close-button:hover {
    background-color: transparent;
  }

  .modal-title {
    font-size: 18px;
    font-weight: 600;
    margin-top: 32px;
  }

  .promotion-plan {
    padding-top: 20px;
    padding-inline: 0;
  }

  .promotion-code-entry {
    margin-top: 8px;
  }

  .checkout-input::-webkit-input-placeholder {
    right: auto;
    margin-left: 4px;
  }

  .checkout-input-blur::-webkit-input-placeholder {
    margin-left: 0;
  }

  .promotion-plan > div {
    display: block;
  }

  .promotion-plan::after {
    margin-top: 12px;
    text-align: left;
    font-size: 16px;
  }

  .plan-details {
    padding: 8px 0;
    background-color: transparent;
  }

  .button-container {
    margin-top: 150px;
  }
}
</style>