<template>
  <div class="premium-container">
    <img class="premiump-bg" :src="premiumBg" alt="">
    <!-- title -->
    <p class="premium-title">Unlimited, Easy-to-use, Affordable</p>
    <ul class="switcher" @click="handleClickSwitcher">
      <li data-cycle-type="MONTH">Monthly</li>
      <li data-cycle-type="YEAR" class="active" ref="initActivedItem">
        <img :src="giftIcon" />
        Yearly -20% off
      </li>
    </ul>
    <!-- Card container -->
    <div class="card-box-container">
      <ul class="card-box" ref="cardBoxRef" :style="{ transform: `translate3d(${cardScrollX}px, 0, 0)` }"
        @pointerdown="handlePointerDown" @pointerup="handlePointerUp" @pointercancel="handlePointerUp">
        <li class="card-container" v-for="card in cardList" :key="card.name">
          <div class="card-header">
            <!-- card标题 -->
            <p class="card-title">{{ card.title }}</p>
            <!-- 价格 -->
            <div class="card-price">
              <!-- 模拟价格的骨架 -->
              <a-skeleton-button shape="default" size="large" active v-if="isLoading"></a-skeleton-button>
              <p v-else>{{ card.monthlyPrice }}</p>
            </div>

            <p class="price-description">
              {{ card.name === 'Free' ? 'Try for free' : 'Per editor / month' }}
            </p>
            <p class="payment-description">
              {{ card.paymentDescription }}
            </p>
            <!-- card plan描述 -->
            <p class="card-plan-description">{{ card.planDescription }}</p>
          </div>
          <!-- 卡片按钮 -->
          <div
            :class="`card-button relative ${(btnDisabled || isLoading || subscriptionState.packageCode === card.packageCode) ? 'pointer-events-none cursor-default' : ''}`">
            <LoadingOutlined :style="loadingStyle" v-if="card.title === 'Subscription Plan' && btnDisabled" />
            <button
              :class="`${card.name === 'Free' ? 'free-button' : subscriptionState.packageCode === card.packageCode ? 'current-button' : 'upgrade-button'}`"
              @click="handleUpgrade(card)">{{
                subscriptionState.packageCode === card.packageCode ? 'Subscribing' : card.buttonText }}</button>
          </div>
          <!-- 卡片主体 -->
          <div class="card-body">
            <div class="free-body">
              <div class="tool-wrapper">
                <ul class="free-tool-box">
                  <li v-for="(tool, index) in card.priorityList" :key="index"><span>{{ tool }}</span></li>
                </ul>
              </div>

            </div>
          </div>
          <!-- 卡片页脚 -->
          <div class="card-footer" v-if="subscriptionState.packageCode === card.packageCode">
            <div class="footer-box">
              <button @click="handleCancel(card.packageCode)">
                <loading-outlined v-if="cancelLoading" :style="{ transform: 'translate(-10px, -.25em)' }" />
                <span>Cancel subscription</span>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- 向下的箭头 -->
    <img class="pull-down" :src="pullDownIcon" alt="">
    <!-- pricing FAQ -->
    <div class="faq-container">
      <!-- faq标题 -->
      <p class="faq-title">Frequently asked questions</p>
      <ul class="faq-box">
        <li v-for="item in faqList" class="faq-item">
          <div class="faq-item-title">
            <p>{{ item.title }}</p>
          </div>
          <div class="faq-item-content">
            <p>{{ item.content }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <upgrade-modal :currPackageCode="currPackageCode" />
  <cancel-modal />
  <contact-modal />
  <polling-modal />
  <unpaid-modal />
  <appsumo-modal />
  <successful-modal :isShowModal="isShowSuccessModal" @close="handleSuccessModalClose" :title="SuccessModalTitle" />
</template>

<script setup>
import { watchEffect, ref, watch, onBeforeMount, onMounted, reactive, provide, onBeforeUnmount } from 'vue';
import UpgradeModal from './modal/upgrade-modal.vue';
import CancelModal from './modal/cancel-modal.vue';
import ContactModal from './modal/contact-modal.vue';
import PollingModal from './modal/polling-modal.vue';
import UnpaidModal from './modal/unpaid-modal.vue';
import AppsumoModal from './modal/appsumo-modal.vue';
import bestValue from '@/assets/premium/best_value.svg';
import premiumBg from '@/assets/premium/premium_bg.webp';
import pullDownIcon from '@/assets/premium/pull_down.svg';
import { usePremiumStore } from '@/store/premium.js';
import { authenticator } from '@/utils';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { isLogin } from '@/utils/auth.js';
import { getSubscribeState } from '@/api/userInfo';
import { modalManager } from '@/components/boolv-ui/global-modal/reactive';
import {
  faqList,
  freePriorityList,
  proPriorityList,
  ultraPriorityList,
  freePlanDescription,
  proPlanDescription,
  ultraPlanDescription,
  freePriorityDescription,
  proPriorityDescription,
  ultraPriorityDescription,
} from './constants.js'
import {
  getOrderState,
  userSubscribe,
  getOperType,
} from "@/api/premium/index";

import { destroy, MessageError, MessageSuccess, MessageWarning } from '@/components/boolv-ui/Message';
import { eventTracking } from "@/utils/eventTracking.js";
import { LoadingOutlined } from '@ant-design/icons-vue';
import creditsInfoIcon from '@/assets/premium/credits_info.svg';
import giftIcon from '@/assets/premium/gift_icon.png';
import successfulModal from './modal/successful-modal.vue';

const premium = usePremiumStore();
const { isAppsumoSubscribedClick } = storeToRefs(premium);
const router = useRouter();
const {
  isCancelClicked,
  isUpdateClicked,
  isPollingClicked,
  isUnpaidClick,
  subscriptionList,
  unpaidExpireTime
} = storeToRefs(premium);
const currPackageCode = ref(null)
const isLoading = ref(true);
const orderId = ref(null);
const btnDisabled = ref(false);
const cancelLoading = ref(false);
const isShowSuccessModal = ref(false)
const SuccessModalTitle = ref('')
const subscriptionState = ref({ packageCode: 201001 });
const cycleType = ref('MONTH');
provide('cycleType', cycleType);

const currentSelectedOrder = ref(0);
provide('currentSelectedOrder', currentSelectedOrder);
provide('currPackageCode', currPackageCode);
const planDetails = reactive({
  originalPrice: '0',
  nextSubStartTimeMills: 0,
  currentSubStartTimeMills: 0,
  presentProrate: null,
  presentProrateDescription: '',
  originalProrate: null,
  originalProrateDescription: '',
  totalPrice: '0',
  nextPeriodSubPriceFormat: ''
})
provide('planDetails', planDetails);
const isPaid = ref(true);
provide('isPaid', isPaid);
const operType = ref('');
provide('operType', operType);
const cardBoxRef = ref(null);
const cardScrollX = ref(0);
const loadingStyle = reactive({
  position: 'absolute',
  left: '50px',
  top: '50%',
  color: '#fff',
  transform: 'translate(-50%,-50%)',
})

//订阅状态，卡片根据状态渲染按钮
const cardList = reactive([
  {
    name: 'Pro',
    title: 'Pro',
    price: '',
    monthlyPrice: '',
    packageCode: null,
    planDescription: proPlanDescription,
    buttonText: 'Pro Plan',
    priorityDescription: proPriorityDescription,
    priorityList: proPriorityList,
    paymentDescription: '',
    cycleType: '',
  },
  {
    name: 'Ultra',
    title: 'Ultra',
    price: '',
    monthlyPrice: null,
    packageCode: null,
    planDescription: ultraPlanDescription,
    buttonText: 'Ultra Plan',
    priorityDescription: ultraPriorityDescription,
    priorityList: ultraPriorityList,
    paymentDescription: '',
    cycleType: '',
  },
]);

const preload = () => {
  const img1 = new Image();
  img1.src = premiumBg;
  const img2 = new Image();
  img2.src = bestValue;
};

const createImgDom = () => {
  var capterra_vkey = 'f6b08fc57b5a641d8c2af1c882662a35', capterra_vid = '2227984', ct = document.createElement('img');
  ct.src = 'https://ct.capterra.com/capterra_tracker.gif?vid=' + capterra_vid + '&vkey=' + capterra_vkey;
  ct.className = 'source_image'
  document.body.appendChild(ct);
  console.log('支付成功回调 创建img dom')
}
// 轮询订单支付结果
const paymentPolling = () => {
  let count = 0;
  new Promise((resolve, reject) => {
    isPollingClicked.value = true;
    function call () {
      getOrderState(orderId.value).then((res) => {
        const { data } = res;
        if (data.state === 'FINISH') {
          resolve(data.packageCode);
        }
        else if (count > 7) {
          reject();
        }
        else {
          count += 1;
          setTimeout(() => {
            call();
          }, 1000);
        }
      })
    }
    call();
  }).then(async (packageCode) => {
    eventTracking("booltool_subscribe_confirm", { is_success: true, subscription_type: 'new' });
    const source_image = document.getElementsByClassName('source_image')[0]

    if (!source_image) {
      createImgDom()
    }

    // 支付回调上报
    gtag('event', 'payment_successful', {
      'package_code': packageCode,
      'isProduct': false
    })
    console.log('支付成功=====', packageCode)

    await authenticator.refresh();
    await updateSubscriptionState();

    isPollingClicked.value = false;
    isShowSuccessModal.value = true;
    SuccessModalTitle.value = 'Upgrade successfully!'
    // MessageSuccess("Upgrade successfully");
    setTimeout(() => {
      destroy();
    }, 2000);
  }, (reason => {
    eventTracking("booltool_subscribe_confirm", { is_success: false, subscription_type: 'new' });
    router.push({ path: '/premium', params: {} });
    isPollingClicked.value = false;
    MessageError("Payment failed");
    setTimeout(() => {
      destroy();
    }, 2000);
  }));
};

async function handleClickSwitcher (e) {
  if (isLoading.value) {
    return;
  }

  isLoading.value = true;
  const target = e.target.closest('.switcher > li');
  await handleSwitch(target);
  isLoading.value = false;
}

let activedItem = null;
const initActivedItem = ref(null);
async function handleSwitch (target) {
  // 更新选择状态
  if (target === null) {
    return;
  }

  if (activedItem !== null) {
    activedItem.classList.remove('active');
  }

  target.classList.add('active');
  activedItem = target;

  // 切换订阅列表
  await premium.fetchSubscriptionList(target.dataset.cycleType);
  subscriptionList.value.forEach((item, index) => {
    cardList[index] = {
      ...cardList[index],
      price: item.subscriptionCycle === 'YEAR' ? item.yearlyPrice : item.monthlyPrice,
      monthlyPrice: item.monthlyPrice,
      packageCode: item.packageCode,
      cycleType: item.subscriptionCycle,
      paymentDescription: item.subscriptionCycle === 'YEAR' ? `Billed yearly as ${item.yearlyPrice}` : 'Billed monthly',
    };
  });
}

async function updateSubscriptionState () {
  const data = await new Promise((resolve, reject) => {
    getSubscribeState().then((res) => {
      const { code, data = { packageCode: 201001 } } = res;
      code === 0 ? resolve({ packageCode: data.packageCode }) : reject(res.msg);
    }).catch(reject);
  });

  subscriptionState.value = data;
}

provide('subscriptionState', [subscriptionState, updateSubscriptionState]);

function calcBoxScroll (deltaX) {
  const availableWidth = cardBoxRef.value.offsetWidth - cardBoxRef.value.parentElement.clientWidth;

  cardScrollX.value = Math.min(0, Math.max(-availableWidth, cardScrollX.value + deltaX));
}

let offsetX = 0;   // 兼容IOS
function handlePointerDown (e) {
  offsetX = e.clientX;
  window.addEventListener('pointermove', handlePointerMove);
}

function handlePointerMove (e) {
  const movementX = (e || window.event).movementX || (e.clientX - offsetX);
  offsetX = e.clientX;
  calcBoxScroll(movementX);
}

function handlePointerUp (e) {
  window.removeEventListener('pointermove', handlePointerMove);
}

const resizeObserver = new ResizeObserver(() => calcBoxScroll(0));
onMounted(async () => {
  resizeObserver.observe(cardBoxRef.value.parentElement);

  preload();
  isLoading.value = true;
  isLogin.value && await updateSubscriptionState();
  await premium.fetchSubscriptionList();

  let targetElement = activedItem = initActivedItem.value;
  const subscriptionCycle = premium.getSubscriptionInfo(subscriptionState.value.packageCode)?.subscriptionCycle;
  if (subscriptionCycle !== undefined) {
    targetElement = targetElement.parentElement.querySelector(`:scope > [data-cycle-type="${subscriptionCycle}"]`) ?? targetElement;
  }

  await handleSwitch(targetElement);
  // initSubscribeState();
  isLoading.value = false;

  //检查是否是从支付页面返回
  const param = window.location.search.split('orderId=')[1];
  if (param) {
    orderId.value = param;
  }
});

watchEffect(() => {
  if (orderId.value) {
    paymentPolling();
  }
});

const handleCancel = (packageCode) => {
  if (cancelLoading.value) {
    return;
  }

  cancelLoading.value = true;
  getOperType({ packageCode, cancelOperBoolean: true }).then(({ data }) => {
    const { currentPeriodPaymentStatus } = data;

    isPaid.value = currentPeriodPaymentStatus === 'paid';
    isCancelClicked.value = true;
    cancelLoading.value = false;
    eventTracking("booltool_subscription_cancel");
  });
};

const handleUpgrade = async ({ packageCode, name, cycleType: targetCycleType }) => {
  currPackageCode.value = packageCode
  if (name === 'Free') {
    return
  }
  //解析数据中的planDetails
  function parsePlanDetails (data) {
    const { subPriceHeader, subPriceBody } = data;
    planDetails.originalPrice = subPriceHeader.originalPriceFormat;
    planDetails.nextSubStartTimeMills = subPriceBody.nextSubStartTimeMills;
    planDetails.currentSubStartTimeMills = subPriceBody.currentSubStartTimeMills;
    planDetails.totalPrice = subPriceBody.totalFormat;
    planDetails.nextPeriodSubPriceFormat = subPriceBody.nextPeriodSubPriceFormat;
    if (subPriceBody.itemList && subPriceBody.itemList.length > 0) {
      subPriceBody.itemList.forEach(item => {
        if (item.amount > 0) {
          planDetails.presentProrate = item.amountFormat;
          planDetails.presentProrateDescription = item.description;
          console.log(item, '>0');
        }
        else {
          planDetails.originalProrate = item.amountFormat;
          planDetails.originalProrateDescription = item.description
          console.log(item, '<0');
        }
      })
    }
  }
  if (isLogin.value) {
    cycleType.value = targetCycleType;
    btnDisabled.value = true;
    const payload = { packageCode };
    eventTracking("booltool_subscribe_click", { plan_option: packageCode });
    try {
      await getOperType(payload).then(async (res) => {
        const { data, code } = res;

        if (code === 0) {
          if (data.currentPeriodPaymentStatus === 'unpaid') {
            isUnpaidClick.value = true;
            unpaidExpireTime.value = data.currentPeriodUnpaidDTO.unpaidSubCancelTime
          } else {
            operType.value = data.subscriptionOperType;

            switch (data.subscriptionOperType) {
              case 'NEW_SUBSCRIPTION':
              case 'UPGRADE_SUBSCRIPTION':
              case 'DEGRADE_SUBSCRIPTION':
                currentSelectedOrder.value = packageCode;
                parsePlanDetails(data);
                // 弹出升级弹窗
                isUpdateClicked.value = true;
                break;
              case 'RECOVER_SUBSCRIPTION':
                await userSubscribe(payload).then(async res => {
                  const { code, data } = res;
                  if (code === 0 && data.subscriptionOperType === 'RECOVER_SUBSCRIPTION') {
                    eventTracking("booltool_subscribe_confirm", { is_success: true, subscription_type: 'recover', plan_option: payload.packageCode });
                    await updateSubscriptionState();
                    // MessageSuccess("Successfully recover your subscription");
                    isShowSuccessModal.value = true;
                    SuccessModalTitle.value = 'Successfully recover your subscription!'
                  } else {
                    eventTracking("booltool_subscribe_confirm", { is_success: false, subscription_type: 'recover', plan_option: payload.packageCode });
                  }
                })
                break;
              default:
                break;
            }
          }
        }
      }).catch((data) => {
        if (data.code === 101004) {
          // appsumo订阅了之后点击订阅按钮
          isAppsumoSubscribedClick.value = true;
        } else if (data.code === 101008) {
          modalManager.applyTemplate('switch_to_monthly');
        }
      })
    }
    catch (e) {
      MessageError('Something wrong');
    }
    finally {
      btnDisabled.value = false;
    }

  }
  else {
    router.push({ path: '/login', query: { redirect: '/premium' } })
  }
};

const handleSuccessModalClose = () => {
  isShowSuccessModal.value = false
};

onBeforeUnmount(() => {
  const source_image = document.getElementsByClassName('source_image')[0]
  source_image?.remove()

  resizeObserver.disconnect()
});
</script>

<style lang="less" scoped>
.premium-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 390px;
  margin-inline: auto;
  z-index: 10;
  background: transparent;
}

.premiump-bg {
  position: absolute;
  height: 650px;
  width: 100%;
  overflow: hidden;
  z-index: -1;
  object-fit: fill;
  top: -60px;
}

.card-container:last-of-type::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 183px;
  height: 183px;
  transform: translate(50%, -50%);
  background: url('~@/assets/premium/best_value.svg') no-repeat center/cover;
}


.premium-title {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 54px;
}

.card-box-container {
  display: flex;
  justify-content: center;
}

.card-box {
  @apply mx-lv;

  display: grid;
  justify-items: center;
  grid-gap: 32px;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1250px;
  min-width: 1020px;
  margin-top: 62px;
  user-select: none;
  touch-action: pan-y;
}

.card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 87%;
  max-width: 430px;
  padding: 0 62px 54px;
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid #DEE0E3;
  border-radius: 8px;
  box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.08);
  // margin-right: 42px;
  // margin-left: 42px;
}

.card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #060606;
  margin-top: 32px;
}

.card-price {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  color: #875EFF;
  margin-top: 12px;
}

.card-plan-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #060606;
  height: 70px;
  margin: 24px auto;
}

.card-body {
  margin-top: 36px;
}

.free-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.free-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #060606;
}

.tool-wrapper {
  // max-width: 257px;
}

.free-tool-box {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 220%;
  list-style: disc;
  color: #646A73;
  padding-left: 25px;

  &>li {
    text-align: start;

    // clear: both;
    &::marker {
      font-size: 0.5em;
      margin-right: 5px;
    }

    &>span {
      vertical-align: middle;
    }
  }
}

.card-button {
  width: 255px;
  height: 48px;
  border-radius: 36px;
  font-size: 16px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
}

.free-button {
  width: 100%;
  height: 100%;
  border-radius: 36px;
  color: #8F959E;
  border: 1px solid #D0D0D0;
  cursor: default;
}

.upgrade-button {
  width: 100%;
  height: 100%;
  border-radius: 36px;
  color: #FFFFFF;
  transition: all 0.5s;
  background: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%);
  border: 1px solid transparent;

  &:hover {
    background: linear-gradient(289deg, #6F46F4 5.38%, #957AEC 99.95%);
  }
}

.current-button {
  width: 100%;
  height: 100%;
  border-radius: 36px;
  border: 1px solid #875EFF;
  color: #875EFF;
  cursor: default;
}

.card-footer {
  // margin-top: 16px;
  // margin-bottom: 26px;
  color: #646A73;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  position: absolute;
  bottom: 24px;
}

.footer-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 24px;
  text-decoration: underline;

  &:hover {
    color: #875EFF;
    text-decoration-color: #875EFF;
  }
}

.pull-down {
  width: 48px;
  margin-top: 75px;
}

.faq-container {
  @apply w-lv;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 54px;
  margin-bottom: 449px;
}

.faq-title {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
}

.faq-box {
  margin-top: 30px;
}

.faq-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-block: 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: left;

  &:not(:last-child) {
    border-bottom: 1px solid #E5E5E5;
  }
}

.faq-item-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #8F959E;
  margin-top: 8px;

  &>ul {
    list-style: disc;
    padding-left: 20px;
  }
}

.register-button {
  width: 100%;
  height: 100%;
  border-radius: 36px;
  cursor: pointer;
  border: 1px solid #8E68FF;
  color: #8E68FF;
}

.switcher {
  display: flex;
  align-items: center;
  padding: 3px;
  margin-top: 30px;
  border-radius: 9999px;
  color: #fff;
  background: rgba(255, 255, 255, 0.13);

  &>li {
    min-width: 180px;
    padding: 16px 20px;
    background-color: transparent;
    transition: background-color .2s;
    border-radius: 9999px;
    cursor: pointer;
    user-select: none;

    &.active {
      background-color: #000;
    }

    &>img {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
}

.price-description {
  margin-top: 12px;
  font-size: 16px;
}

.payment-description {
  margin-top: 4px;
  font-size: 16px;
  color: #646A73;

  &::after {
    content: '\200B';
  }
}

.card-box-container {
  width: 100%;
  overflow-x: clip;
}

@screen ms {
  .card-box {
    grid-gap: 0;
  }

  .card-container:last-of-type::before {
    content: 'Best Value';
    width: fit-content;
    height: auto;
    padding: 3px 10px;
    background-image: none;
    background-color: #FFDE2E;
    border-radius: 2px;
    transform: translate(1px, -40%);
    font-weight: 500;
    font-size: 14px;
  }

  .card-box-container {
    justify-content: flex-start;
  }
}

@screen m {
  .faq-container {
    @apply w-mv;

    margin-block: 45px 54px;
  }

  .premium-title {
    font-size: 28px;
    margin-top: 45px;
  }

  .card-box {
    @apply px-mv;

    margin-top: 46px;
  }

  .card-title {
    margin-top: 28px;
  }

  .faq-box {
    margin-top: 20px;
  }

  .faq-item {
    font-size: 17px;
  }

  .faq-title {
    font-size: 26px;
  }
}

@screen s {
  .switcher>li {
    padding: 14px 16px;
    min-width: 150px;
  }

  .faq-container {
    @apply w-sv;

    margin-block: 36px 32px;
  }

  .premium-title {
    font-size: 20px;
    margin-top: 36px;
  }

  .card-box {
    @apply px-sv mx-0;

    margin-top: 34px;
  }

  .card-title {
    margin-top: 24px;
  }

  .free-button,
  .card-button {
    font-size: 15px;
  }

  .faq-box {
    margin-top: 10px;
  }


  .faq-title {
    font-size: 20px;
  }

  .pull-down {
    width: 42px;
  }

  .faq-item-title {
    font-size: 18px;
  }

  .faq-item-content {
    font-size: 16px;
  }
}
</style>
