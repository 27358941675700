<template>
  <div>
    <custom-modal :zIndex="1100" :visible="isModalVisible" :closable="false" :footer="null" class="upgrade-modal-container">
      <template #modal-title>
        <div class="modal-header flex items-center justify-between">
          <div class="flex items-center modal-title">
            {{ isPaid ? 'Confirm Cancellation' : 'Contact us' }}
          </div>

          <div class="close_icon-container">
            <img :src="close_icon" class="close-icon" @click="handleCloseModal" />
          </div>
        </div>
      </template>
      <template #modal-content>
        <div class="modal-body" :style="{ 'backgroundColor': 'transparent', 'padding': '32px 0' }"
          v-if="subscriptionInfo.dueTime">
          <template v-if="isPaid">
            Your subscription will end on {{ dayjs(subscriptionInfo.dueTime).format('MM/DD/YYYY') }}.
          </template>
          <template v-else>
            <p>
              Our Email:
              <a class="modal-body-a" style="color:#a987ff" href="mailto:support@boolvector.com" target="_blank"
                rel="noreferrer">
                support@boolvector.com
              </a>
            </p>
            <p style="margin-top: 6px">Email us if you need to cancel your subscription. We will get in touch with you
              within 24 hours.</p>
          </template>
        </div>
      </template>
      <template #operate-button>
        <div :class="['flex', isPaid ? 'justify-center' : 'justify-end']">
          <custom-btn v-if="isPaid" :customStyle="customStyle" :showPrefixIcon="false"
            activeColor="linear-gradient(289deg, #6F46F4 5.38%, #957AEC 99.95%)" @click="(e) => handleCancel(e)"
            :disabled="isLoading">
            <div class="flex items-center justify-center">
              <div class="flex items-center relative">
                <LoadingOutlined :style="{ position: 'absolute', left: '-21px' }" v-show="isLoading" />
                <p>Yes, i'm sure</p>
              </div>
            </div>
          </custom-btn>
          <template v-else>
            <button class="cancel-button" @click="handleCloseModal">Cancel</button>
            <button class="cancel-button" @click="handleCloseModal"
              style='color: white; margin-left: 12px; background-image: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)'>
              Get
            </button>
          </template>
        </div>
      </template>
    </custom-modal>
  </div>
</template>

<script setup>
import close_icon from '@/assets/common-icons/icon_close.svg'
import CustomModal from '@/components/boolv-ui/custom-modal/custom-modal.vue'
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons-vue';
import { usePremiumStore } from '@/store/premium.js'
import { useSubscriptionInfo } from '@/store/user.js'
import { storeToRefs } from 'pinia'
import { debounce } from 'lodash';
import { reactive, ref, watchEffect, onMounted, inject } from 'vue';
import {
  cancelSubscription
} from "@/api/premium/index";
import {
  MessageSuccess,
  MessageError,
  destroy,
} from "@/components/boolv-ui/Message/index.js";
import { useUserStore } from '@/store/user.js'
import { eventTracking } from "@/utils/eventTracking.js";
const user = useUserStore();
const premium = usePremiumStore();
const subscriptionInfo = useSubscriptionInfo();
const { isCancelClicked } = storeToRefs(premium);
const isLoading = ref(false);
const isModalVisible = ref(false);
const halfHeight = ref(0);
const isPaid = inject('isPaid');
const [subscriptionState, updateSubscriptionState] = inject('subscriptionState');

onMounted(async () => {
  const screenHeight = window.innerHeight;
  halfHeight.value = screenHeight / 2;
})
watchEffect(() => {
  if (isCancelClicked.value) {
    isModalVisible.value = true
  }
})
const handleCloseModal = () => {
  isCancelClicked.value = false;
  isModalVisible.value = false;
}
const handleCancel = debounce(() => {
  isLoading.value = true;
  try {
    cancelSubscription().then(async res => {
      const { code } = res;

      if (code === 0) {
        eventTracking("booltool_cancel_confirm", { is_success: true });
        await updateSubscriptionState();
        MessageSuccess("Cancellation successful");
      } else {
        eventTracking("booltool_cancel_confirm", { is_success: false });
        MessageError("Cancellation failed");
      }
    })
  }
  finally {
    isLoading.value = false;
    isCancelClicked.value = false;
    isModalVisible.value = false;
    setTimeout(() => {
      destroy();
    }, 2000)
  }


}, 500)
const customStyle = reactive({
  width: '160px',
  height: '36px',
  background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)',
  'border-radius': '60px',
});
</script>

<style lang="less" scoped>
.modal-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1F2329;
}

.close_icon-container {
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #EDEDED;
  }
}

.close-icon {
  width: 20px;
  height: 20px;
}

.modal-body {
  padding: 18px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #060606;
  background: #F5F6F7;
}

.cancel-button {
  width: 96px;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  border: 1px solid #E0E0E0;
}

:global(.upgrade-modal-container .ant-modal-content) {
  border-radius: 8px;
}

:global(.upgrade-modal-container .ant-modal-header) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>
