export const freePriorityList = [
  'Portrait Background Remover',
  'Background Blur',
  'Photo Filters and Effects',
  'Photo Animation Effects',
  'Image Resizer',
  'Image Compressor',
  'Sugar Comments',
];
export const freePlanDescription = 'Best for individuals looking for simple image editing tools.';
export const freePriorityDescription = '';
export const proPriorityList = [
  'Unlimited Background Remover',
  'Unlimited Object Eraser',
  'Unlimited Image Enhancer',
  'Unlimited Animation and Filters',
  'Unlimited Image Resizer ',
  'Unlimited Image Compressor',
  'Unlimited Background Blur',
  'Unlimited Copywriting Tools',
  '10 Batch Processing / month',
];
export const proPlanDescription = 'Best for small teams, freelancers, and designers looking to improve efficiency with AI magic tools.';
export const proPriorityDescription = 'Everything Free Plan offers, and:';
export const ultraPriorityList = [
  'Unlimited Background Remover',
  'Unlimited Object Eraser',
  'Unlimited Image Enhancer',
  'Unlimited Animation and Filters',
  'Unlimited Image Resizer',
  'Unlimited Image Compressor',
  'Unlimited Background Blur',
  'Unlimited Copywriting Tools',
  'Unlimited Batch Processing',
  '60 min Video Tools / month',
];
export const ultraPlanDescription = 'Best for growing teams looking to create visuals and content at scales.';
export const ultraPriorityDescription = 'Everything Free Plan offers, and:';

export const faqList = [
  {
    title:   'Can I upgrade my subscription plan?',
    content: `Yes, you can upgrade your subscription plan at any time.
              Any unused time on your current plan will be credited back to you and reduce the cost of the upgrade.
              After you confirm, your account will immediately get upgraded.
              Go to the Pricing section and take your account to the next level now!`,
  },
  {
    title:   'Can I downgrade my subscription plan?',
    content: `Yes, you can downgrade your subscription plan at any time.
              The downgrade will take effect during the next billing cycle.`,
  },
  {
    title:   'Can I cancel my subscription plan?',
    content: `You can cancel your monthly subscription at any time.
              The cancellation will take effect during the next billing cycle.
              We hope you'll fall in love with Booltool and never want to leave :)`,
  },
  {
    title:   'How do I request a refund?',
    content: `Usually, we don't process refunds, since our product is fully digital and can be tested for free beforehand.
              However, if you are not 100% happy, contact us!
              As a startup, we will try our best to deliver delightful experiences for our customers :)`,
  },
  {
    title:   'Is this a secure site for purchases?',
    content: `We process all transactions via Stripe, which guarantees your safety and security.
              All of the communication between you and our site are encrypted and secured!`,
  },
];

/**
 * @see subscriptionInfoList ~@/utils/eventTracking.js
 */
export const cycleTypes = {
  YEAR: {
    name: 'year',
    cycle: 'yearly'
  },
  MONTH: {
    name: 'month',
    cycle: 'monthly'
  },
};
