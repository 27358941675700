<template>
  <div>
    <custom-modal :zIndex="1100" :visible="isModalVisible" :closable="false" :footer="null" class="successful-modal-container">
      <template #modal-title>
        <div class="modal-header flex justify-between ">
          <div class="flex items-center justify-center modal-title flex-1 text-white">
            <img :src="icon_successful" class="icon_successful" /> {{ title }}
          </div>
          <div class="close-icon-container flex items-center justify-center">
            <svg width="15.46" height="15.46" viewBox="0 0 15.46 15.46" fill="none" xmlns="http://www.w3.org/2000/svg"
              class="close-icon" @click="handleCloseModal">
              <path
                d="M7.99988 6.82149L14.4311 0.390224C14.5939 0.227505 14.8577 0.227505 15.0204 0.390224L15.6097 0.979479C15.7724 1.1422 15.7724 1.40602 15.6097 1.56873L9.17839 8L15.6097 14.4313C15.7724 14.594 15.7724 14.8578 15.6097 15.0205L15.0204 15.6098C14.8577 15.7725 14.5939 15.7725 14.4311 15.6098L7.99988 9.17851L1.56862 15.6098C1.4059 15.7725 1.14208 15.7725 0.979361 15.6098L0.390105 15.0205C0.227387 14.8578 0.227387 14.594 0.390105 14.4313L6.82137 8L0.390105 1.56873C0.227387 1.40602 0.227387 1.1422 0.390105 0.979479L0.979361 0.390224C1.14208 0.227505 1.4059 0.227505 1.56862 0.390224L7.99988 6.82149Z"
                fill="white" />
            </svg>
          </div>
        </div>
      </template>
      <template #modal-content>
        <div class="modal-body">
          <p> As our esteemed member, you will :</p>
          <ul class="text-container">
            <li v-for="item in textList" :key="item.id">{{ item.text }}</li>
          </ul>
          <p style="color: rgba(83, 85, 88, 1)"> We are always here to provide assistance and support to ensure you have a
            pleasant
            experience while using
            our services.</p>
        </div>
      </template>
    </custom-modal>
  </div>
</template>

<script setup>
import CustomModal from '@/components/boolv-ui/custom-modal/custom-modal.vue'
import icon_successful from '@/assets/common-icons/icon_successful.svg'
import { ref, watch, defineProps, defineEmits } from 'vue';

const props = defineProps({
  isShowModal: Boolean,
  title: String
})

const emits = defineEmits(['close'])
const isModalVisible = ref(false);
const textList = [
  {
    id: 1,
    text: 'Enjoy exclusive features'
  }, {
    id: 2,
    text: 'Priority support'
  }, {
    id: 3,
    text: 'Regular updates'
  }, {
    id: 4,
    text: 'Unique privileges'
  }
]

watch(() => props.isShowModal, (value) => {
  isModalVisible.value = value;
})

const handleCloseModal = () => {
  isModalVisible.value = false;
  emits('close', true)
}

</script>

<style lang="less" scoped>
.modal-title {
  font-weight: 500;
  font-size: 16px;
  margin-block: 31.5px;
  font-weight: 400;
  font-size: 21px;
  line-height: 24px;

  .icon_successful {
    width: 28.67px;
    height: 28.67px;
    margin-right: 11.67px;
  }

}

.modal-body {
  font-family: 'Inter', Arial;
  padding: 24px 8px 12px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #060606;

  .modal-body-a {
    color: #875EFF;

    &:hover {
      color: #875EFF;
    }
  }
}

.close-icon-container {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: rgba(222, 224, 227, 0.6);
  }
}

.close-icon {
  cursor: pointer;
  border-radius: 4px;
}

:global(.successful-modal-container) {
  max-width: calc(100vw - 64px);
}

:global(.successful-modal-container .ant-modal-content) {
  border-radius: 8px;
}

:global(.successful-modal-container .ant-modal-header) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 18px;
  background-image: url('~@/assets/bg-images/successful_bg.png');
  background-position: 50% 50%;
  background-size: cover;
}

:global(.successful-modal-container .ant-modal-title) {
  height: 100%;
}

.text-container {
  font-family: 'Inter', Arial;
  color: #1F2329;
  padding: 5px 0 5px 10px;

  li::before {
    content: '';
    display: inline-block;
    vertical-align: 2px;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    margin-right: 5px;
    background-color: #1F2329;
  }
}

@screen s {
  .modal-header {
    flex-direction: column;
  }

  .modal-title {
    flex-direction: column;
    margin-block: 0 10px;
  }

  .modal-title > .icon_successful {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .close-icon-container {
    width: 100%;
    order: -1;
    justify-content: flex-end;
  }
}
</style>