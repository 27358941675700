<template>
  <div>
    <custom-modal :zIndex="1100" :visible="isModalVisible" :closable="false" :footer="null" class="upgrade-modal-container">
      <template #modal-title>
        <div class="modal-header flex items-center justify-between">
          <div class="flex items-center modal-title">
            Update Plan
          </div>

          <img :src="close_icon" class="close-icon" @click="handleCloseModal" />
        </div>
      </template>
      <template #modal-content>
        <div class="modal-body">
          <p>
            You have already subscribed to a lifetime plan and are not eligible to subscribe to an addtional subscription
            plan at this time.
          </p>
          <p>If you have any questions, please contact: support@boolvector.com</p>
        </div>
      </template>
      <template #operate-button>
        <div class="flex justify-center pt-6">
          <custom-btn :customStyle="customStyle" :showPrefixIcon="false" @click="(e) => handleGetit(e)"
            activeColor="linear-gradient(289deg, #6F46F4 5.38%, #957AEC 99.95%)">
            Got it
          </custom-btn>
        </div>
      </template>
    </custom-modal>
  </div>
</template>

<script setup>
import close_icon from '@/assets/common-icons/icon_close.svg'
import CustomModal from '@/components/boolv-ui/custom-modal/custom-modal.vue'
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";
import { usePremiumStore } from '@/store/premium.js'
import { storeToRefs } from 'pinia'
import dayjs from 'dayjs';
import { reactive, ref, watchEffect } from 'vue';
import {
  userSubscribe
} from "@/api/premium/index";
const premium = usePremiumStore();
const { isAppsumoSubscribedClick } = storeToRefs(premium);
const isModalVisible = ref(false);

watchEffect(() => {

  if (isAppsumoSubscribedClick.value) {
    isModalVisible.value = true
  }
})
const handleCloseModal = () => {
  isAppsumoSubscribedClick.value = false;
  isModalVisible.value = false;
}
const handleGetit = () => {

  isAppsumoSubscribedClick.value = false;
  isModalVisible.value = false;
}
const customStyle = reactive({
  width: '160px',
  height: '36px',
  background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)',
  'border-radius': '60px',
})
</script>

<style lang="less" scoped>
.modal-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1F2329;
}

.modal-body {
  padding: 18px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #060606;
  background: #F5F6F7;
  margin-top: 12px;

  .modal-body-a {
    color: #875EFF;

    &:hover {
      color: #875EFF;
    }
  }
}

.close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: #EDEDED;

  }
}

:global(.upgrade-modal-container .ant-modal-content) {
  border-radius: 8px;
}

:global(.upgrade-modal-container .ant-modal-header) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>