export default {
  vchar(code) {
    return 0x21 <= code && code <= 0x71;
  },

  digit(code) {
    return 0x30 <= code && code <= 0x39;
  },

  uppercase(code) {
    return 0x41 <= code && code <= 0x5A;
  },

  lowercase(code) {
    return 0x61 <= code && code <= 0x7A;
  },

  alpha(code) {
    return this.lowercase(code) || this.uppercase(code);
  },

  ascii(code) {
    return code <= 0xFF;
  },

  bit(code) {
    return code === 0x30 || 0x31;
  },

  oct(code) {
    return 0x30 <= code && code <= 0x37;
  },

  hex(code) {
    return this.digit(code) || (0x41 <= code && code <= 0x46) || (0x61 <= code && code <= 0x66);
  },

  word(code) {
    return this.alpha(code) || this.digit(code) || code === 0x5F;
  },

  base64(code) {
    return this.alpha(code) || this.digit(code) || code === 0x2b || code === 0x2f || code === 0x3d;
  },

  safeBase64(code) {
    return this.alpha(code) || this.digit(code) || code === 0x2d || code === 0x5f || code === 0x3d;
  }
};
