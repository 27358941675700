<template>
  <div>
    <custom-modal :zIndex="1100" :visible="isModalVisible" :closable="false" :footer="null" class="upgrade-modal-container">
      <template #modal-title>
        <div class="modal-header flex items-center justify-between">
          <div class="flex items-center modal-title">
            Waiting...
          </div>
        </div>
      </template>
      <template #modal-content>
        <div class="modal-body">
          <a-spin tip="Loading for subscription feadback..." size="large" />
        </div>
      </template>
      <template #operate-button>
        <div class="flex justify-center pt-6">
        </div>
      </template>
    </custom-modal>
  </div>
</template>

<script setup>

import CustomModal from '@/components/boolv-ui/custom-modal/custom-modal.vue'
import { usePremiumStore } from '@/store/premium.js'
import { storeToRefs } from 'pinia'
import { reactive, ref, watchEffect, onMounted } from 'vue';
import { useUserStore } from '@/store/user.js'
const premium = usePremiumStore();
const { isPollingClicked } = storeToRefs(premium);
const isModalVisible = ref(false);
const halfHeight = ref(0);
onMounted(async () => {
  const screenHeight = window.innerHeight;
  halfHeight.value = screenHeight / 2;
})
watchEffect(() => {
  console.log('isPollingClicked---->', isPollingClicked)
  if (isPollingClicked.value) {
    isModalVisible.value = true
    //轮询订单完成情况
  }
  else {
    isModalVisible.value = false;
  }
})
</script>

<style lang="less" scoped>
.modal-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1F2329;
}

.modal-body {
  padding: 18px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #060606;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

:global(.upgrade-modal-container .ant-modal-content) {
  border-radius: 8px;
}

:global(.upgrade-modal-container .ant-modal-header) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>